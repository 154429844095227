import WPhoto_ScotchDoubleVerticalComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/ScotchDouble/ScotchDoubleVertical.skin';
import WPhoto_ScotchDoubleVerticalController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_ScotchDoubleVertical = {
  component: WPhoto_ScotchDoubleVerticalComponent,
  controller: WPhoto_ScotchDoubleVerticalController
};


export const components = {
  ['WPhoto_ScotchDoubleVertical']: WPhoto_ScotchDoubleVertical
};

